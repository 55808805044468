import {useState, useRef, useEffect} from "react";
import {MapContainer, TileLayer} from "react-leaflet";
import {LeafletEvent} from "leaflet";
import L from "leaflet";
import RouteComponent from "./RouteComponent";
import '../../assets/css/custom-map.css'
import MapInfoboxComponent from "./MapInfoboxComponent";

function MapWithRouting({objects, ts}: any) {


    const [mapClass, setMapClass] = useState("route-map-container")
    const mapContainerRef: any = useRef(null);
    const [infoBoxComponent, setInfoBoxComponent] = useState<React.ReactNode | null>(null);
    const [showInfoBox, setShowInfoBox] = useState(false);
    const [scrollAndZoom, setScrollAndZoom] = useState(true)
    const [routing, setRouting] = useState<L.Routing.Control | null>(null);
    const [routeMode, setRouteMode] = useState('walking')
    const clearSelectedPoint = () => {
        document.querySelectorAll('.custom-waypoint-icon').forEach(cwi => {
            cwi.classList.remove('selected')
        })
    }



    const changeRouteMode = (mode: any) => {
        setRouteMode(mode)
        if (routing) {
            const sb = document.querySelectorAll('.map-route-modes .switch-buttons');
            sb.forEach(e => {
                e.classList.remove('selected')
            })
            const routeButton: any = document.querySelector(`.route-${mode}`);
            routeButton.classList.add('selected')
            const router: any = routing.getRouter();
            router.options.profile = `mapbox/${mode}`;
            routing.route();
        }
    }

    const closeInfoBox = () => {
        setMapClass('route-map-container')
        setShowInfoBox(false);
        setInfoBoxComponent(null)
        clearSelectedPoint()

        setTimeout(function () {
            const currentMode : any = document.querySelector('.switch-buttons.selected')
            currentMode.click()
        }, 300)

        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));

        }, 400)

    }
    const wayPointClickEvent = (object: any) => {
        setMapClass('route-map-container with-object')
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 300)
        setShowInfoBox(true);
        const newObjectCenter: any = [object.lat, object.lon];
        setInfoBoxComponent(<MapInfoboxComponent boxData={object} closeInfoBox={closeInfoBox} ts={ts}/>)
        mapContainerRef.current.setView(newObjectCenter, mapContainerRef.current.zoom)


// Dispatch the event on the window

    }

    const setRoutingValue = (routing: any) => {
        setRouting(routing)
    }

    //if(window.outerWidth < 1300) {

    //}

    return (
        <div className={'top30 relative oXH'}>
            <div className={mapClass}>
                <MapContainer
                    id="mapContainer"
                    ref={mapContainerRef}
                    style={{width: '100%', height: "100%"}}
                    className="markercluster-map"
                    zoomAnimation={true}
                    scrollWheelZoom={scrollAndZoom}
                    trackResize={true}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                               attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'/>
                    {objects &&
                    <RouteComponent setRoutingValue={setRoutingValue} clearSelectedPoint={clearSelectedPoint}
                                    wayPointClickEvent={wayPointClickEvent} objects={objects}/>}
                </MapContainer>
            </div>
            <div className={'map-route-modes center top15'}>
                <button onClick={() => {
                    changeRouteMode('walking')
                }} className={'route-walking switch-buttons selected'}><i className={'la la-walking'}/> {ts.walking}
                </button>
                <button onClick={() => {
                    changeRouteMode('driving')
                }}
                        className={'route-driving switch-buttons'}><i className={'la la-car'}/> {ts.byCar} </button>
            </div>
            <div className={showInfoBox ? 'info-box visible' : 'info-box'}>
                {infoBoxComponent}
            </div>
        </div>

    );
}

export default MapWithRouting;
