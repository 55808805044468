import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {IGetData} from "../interfaces/Interfaces";

function SetDataComponent(api: string, criteria: string, setData:IGetData) {
    const effectRan = useRef(false);
    const getData: IGetData = async(c:string) => {
        const result = await axios.get(`${api}/api/${c}`)
        console.log(result.data);
        setData(result.data)
    }
    useEffect(() => {
        if (!effectRan.current) {
            getData(criteria);
            effectRan.current = true;
        }
    }, [])
    return getData;
}

export default SetDataComponent;
