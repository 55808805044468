import React, {useState} from "react";
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import domAction from "../../domAction";

function RouteDetailList({objects, ts}: any) {

    return (
        <div className={'objects-list'}>
            {objects && objects.length > 0 && (objects.map((obj: any, index: number) => {

                return (
                    <div key={index} className={'element flex top15'}>
                        <div className={'object-list-img'}>
                            {obj.images && obj.images.length > 0 && (
                                <Splide
                                    options={{
                                        pagination: false,
                                        perPage: 1,
                                        arrows: obj.images.length > 1
                                    }}
                                    className={'inner-container'}>

                                    {obj.images.map((imgSrc: string, index: any) => (
                                        <SplideSlide key={index}>
                                            <div className={'list-img'}>
                                                <img src={imgSrc}/>
                                            </div>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            )}
                        </div>
                        <div className={'object-list-info'}>
                            <div className={'flex'}>
                                <div className={'object-list-title'}>{obj.orderIndex + 1}. {obj.title}</div>
                                <div className={'title-button'}>
                                    <button onClick={() => {
                                        domAction.setDirectionTarget(`${obj.lat},${obj.lon}`)
                                    }} className={'pointer default-btn'}><i className={'la la-location-arrow nav'} /> {ts.navigation}</button>
                                </div>
                            </div>
                            <div className={'object-list-text'} dangerouslySetInnerHTML={{__html: obj.text}}/>

                        </div>
                    </div>
                )
            }))}
        </div>
    )
}

export default RouteDetailList
