import React, {useEffect, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, Polygon} from "react-leaflet";
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import {IMapComponent, IMapData} from "../interfaces/Interfaces";
import {SofiaPolygon} from "./Polygons";
import "../assets/css/custom-map.css"

function MapComponent({api, data, func}: IMapComponent) {
    const defaultStyle = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    const mapRef: any = React.createRef()
    const markerRefs: any = React.useRef([])
    const [selectedMarker, setSelectedMarker] = useState(null);

    const customIcon = (propIcon: any) => {

        const mapIcon: any = propIcon ? `<div class="${propIcon}"></div>` : `<div class="circle-div"></div>`

        return new L.DivIcon({
            className: `custom-waypoint-icon object`,
            html: `<div class="map-icon">${mapIcon}</div>`,
            iconSize: [36, 36],
            popupAnchor: [0, -18],
        });
    }
    const customIconSelected = (propIcon: any) => {

        const mapIcon: any = propIcon ? `<div class="${propIcon}"></div>` : `<div class="circle-div"></div>`
        return new L.DivIcon({
            className: `custom-waypoint-icon object selected`,
            html: `<div class="map-icon" style="display: block">${mapIcon}</div>`,
            iconSize: [36, 36],
            popupAnchor: [0, -18],
        });
    }

    func.openPopupForMarker = (point: any) => {
        if (mapRef.current) {

            const map = mapRef.current
            if (!map) {
                alert('no map')
                return
            }
            document.querySelectorAll('.leaflet-popup-close-button').forEach(e => {
                if (e) {
                    e.addEventListener('click', e => {
                    });
                    e.dispatchEvent(new Event('click'));

                }
            })
            const marker = markerRefs[point.id].current
            setSelectedMarker(point.position)
            map.setView(point.position, 11)
        }
    };

    if (mapRef.current) {
        mapRef.current.on('popupopen', () => {
            alert('Opeeen!');
        })
    }


    function handleMarkerClick() {
        alert('Click');
        return null;
    }


    return (
        <MapContainer
            center={[42.650756801974115, 23.322399471072874]}
            zoom={10}
            ref={mapRef}
        >
            <TileLayer url={defaultStyle}/>
            {data && data.length > 0 && (
                data.map((point: IMapData, index: number) => {
                    const markerRef: any = React.createRef();
                    markerRefs[point.id] = markerRef;
                    return (
                        <Marker
                            eventHandlers={{
                                click: (e) => {
                                    const selected: any = document.querySelector('.object-list-element.selected')
                                    if (selected) {
                                        selected.classList.remove('selected')
                                    }
                                },
                            }}
                            interactive //Magic happens here
                            key={index}
                            position={point.position}
                            icon={selectedMarker === point.position ? customIconSelected(point.icon) : customIcon(point.icon)}
                            ref={markerRef}>
                            {/* Conditionally render the Popup based on the openMarker state */}
                            <Popup>
                                <div className={'classPopup scroll-container'}>
                                    <div className="img">
                                        <img
                                            src={point.img}
                                            alt={`Image for Point ${point.id}`}
                                            style={{maxWidth: "100%"}}
                                        />
                                    </div>
                                    <p dangerouslySetInnerHTML={{__html: point.text}}/>
                                </div>
                            </Popup>
                        </Marker>
                    )
                }))
            }
            <Polygon positions={SofiaPolygon} color="green"/>;
        </MapContainer>
    )
}


export default MapComponent;
