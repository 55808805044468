import "leaflet/dist/leaflet.css";
import MapWithRouting from "../components/routeo/MapWithRouting";
import {useState} from "react";
import SetDataComponent from "../components/SetDataComponent";
import {useParams} from "react-router-dom";
import i18n from "../i18n";
import RouteDetail from "../components/routeo/RouteDetail";
import RouteDetailList from "../components/routeo/RouteDetailList";
import domAction from "../domAction";

export default function RouteObjectPage({api}: any) {

    const {lg, id} = useParams();
    const ts = i18n(lg)
    const [data, setData]: any = useState([]);
    const getData = SetDataComponent(api, `route-objects?lg=${lg}&routeId=${id}`, setData)
    if (data) {
        return (
            <div className={'containers'} onLoad={domAction.setIframeHeight}>
                <RouteDetail lg={lg} routeData={data.route}/>
                <MapWithRouting objects={data.objects} ts={ts}/>
                <RouteDetailList objects={data.objects} ts={ts}/>
            </div>
        )
    }
    return null;
}

