// src/components/Layout.js
import React from 'react';
import Header from "./Header";

// @ts-ignore
function Layout({ children }) {
    return (
        <div>
            <header>
                <Header/> {/**/}
            </header>
            <main>{children}</main>
        </div>
    );
}

export default Layout;
