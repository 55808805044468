import React, {useState} from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';


// @ts-ignore
function ObjectsListComponent({data, func}) {

    const [activeElement, setActiveElement] = useState(null);

    // Function to handle element click
    const handleElementClick = (index: any) => {
        setActiveElement(index);
    };
    const inactive = 'object-list-element'
    const active = 'object-list-element selected'

    return (
            <Splide
                options={ {
                    rewind: true,
                    gap   : '10px',
                    fixedWidth: 220,
                    pagination: false,
                    perPage: 1
                } }
                className={'inner-container'}>
                {data && data.length > 0 && (
                    data.map((point: any, index: any) => (
                    <SplideSlide  key={index} className={index === activeElement ? active : inactive} onClick={() => {
                        handleElementClick(index)
                        if(func && func.openPopupForMarker)
                            func.openPopupForMarker(point)

                    }} style={{cursor: "pointer"}}>
                        <div className={'list-img'}>
                            <img src={point.img}/>
                        </div>
                        <div className={'list-title'} dangerouslySetInnerHTML={{__html: point.title}}/>
                    </SplideSlide>
                )))}
            </Splide>
    );
}

export default ObjectsListComponent;
