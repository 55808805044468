import {Link, useParams} from "react-router-dom";
import React from "react";
import i18n from "../../i18n";

function RouteDetail({lg, routeData}: any) {
    const ts = i18n(lg)
    //backToAll
    let genUrl = '';
    let genTitle = ''
    if (routeData) {
        genUrl = routeData.backToAll == 1 ? `/${lg}` : `/${lg}/route-preview/${routeData.routeTypeId}`
        genTitle = routeData.backToAll == 1 ? ts.allRoutes : routeData.routeType;
    }
    return (
        <div className={'containers'}>
            {routeData &&
            <div className={'inner-containers'}>
                <div className={'center top50'}>
                    <Link to={genUrl} className={'button'}>
                        <i className={'la la-map-marker'}/> {genTitle}</Link>
                </div>
                <div className={'center top50 page-title'}>{routeData.title}</div>
                <div className={'top30 page-text'} dangerouslySetInnerHTML={{__html: routeData.text}}/>
            </div>
            }
        </div>
    );
}

export default RouteDetail;
