// src/components/Header.js
import React, {useState} from 'react';
import {Link} from 'react-router-dom'; // Import the Link component

function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };



    return (
        <header>
            <nav>

            </nav>
        </header>
    );
}

export default Header;
