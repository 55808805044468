import React, {useState} from "react";
import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import domAction from "../../domAction";

function MapInfoboxComponent({boxData, closeInfoBox, ts}: any) {

    return (
        <div className={'info-box-container'}>
            <div className={'close-info-box'}>
                <i onClick={() => {
                    closeInfoBox()
                }} className={'la la-times'}/>
            </div>
            <div className={'toggle-container scroll-container'}>
                <div className={'img-info-box'}>
                    {boxData.images && boxData.images.length > 0 && (
                        <Splide
                            options={{
                                rewind: true,
                                pagination: false,
                                perPage: 1,
                                arrows: boxData.images.length > 1
                            }}
                            className={'inner-container'}>
                            {boxData.images.map((imgSrc: string, index: any) => (
                                <SplideSlide key={index}>
                                    <div className={'list-img'}>
                                        <img src={imgSrc}/>
                                    </div>
                                </SplideSlide>
                            ))}
                        </Splide>
                    )}
                </div>
                <div className={'title'}>{boxData.orderIndex + 1}. {boxData.title}</div>
                <div className={'text'} dangerouslySetInnerHTML={{__html: boxData.text}}/>
            </div>
            <div className={'center top20'}>
                <button onClick={() => {
                    domAction.setDirectionTarget(`${boxData.lat},${boxData.lon}`)
                }} className={'pointer default-btn'}><i className={'la la-location-arrow nav'}/>{ts.navigation}</button>
            </div>
        </div>
    )
}

export default MapInfoboxComponent
