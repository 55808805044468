import React, {useState} from "react";

import ObjectsListComponent from "../components/ObjectsListComponent";
import SetDataComponent from "../components/SetDataComponent";
import MapComponent from "../components/MapComponent";
import {IGetData, IMapObject} from "../interfaces/Interfaces";
import {Link, useParams} from "react-router-dom";
import BoxList from "../components/BoxList";
import i18n from "../i18n";
import domAction from "../domAction";

function RoutePreviewPage({api}: IMapObject) {
    const {lg, id} = useParams();
    const ts = i18n(lg);
    const func: object = {};
    const [data, setData]: any = useState([]);
    const getData: IGetData = SetDataComponent(api, `routes-preview?lg=${lg}&routeTypeId=${id}`, setData)

    return (
        <div className={'containers'} onLoad={domAction.setIframeHeight}>
            <div className={'inner-containers'}>
                <div className={'center top50'}>
                    <Link to={`/${lg}`} className={'button'}><i className={'la la-map-marker'}/> {ts.allRoutes}</Link>
                </div>
                <div className={'center top50 page-title'}>{data.routeTypeTile}</div>
                <BoxList data={data.routes} lg={lg}/>

                <div className={'title top30'}>{ts.objects}</div>
                <div className={'objects-map-container sm'}>
                    <MapComponent api={api} data={data.objects} func={func}/>
                </div>
                <div className="objects-list-container">
                    <ObjectsListComponent data={data.objects} func={func}/>
                </div>
            </div>
        </div>
    );
}

export default RoutePreviewPage;
