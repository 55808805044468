const translations: any = {
    en: {
        objects: 'Objects',
        routes: 'Routes',
        allRoutes: 'All routes',
        sofiaAround: 'Sofia around me',
        navigation: 'Get Direction',
        walking: 'Walking',
        byCar: 'By Car'
    },
    bg: {
        objects: 'Обекти',
        routes: 'Маршрути',
        allRoutes: 'Всички маршрути',
        sofiaAround: 'София около мен',
        navigation: 'Упътване',
        walking: 'Пеша',
        byCar: 'Кола'
    },
}

function i18n(lg: any) {
    return translations[lg] || null
}

export default i18n;
