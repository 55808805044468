import React, {useState} from "react";
import {Link} from "react-router-dom";

function BoxList({data, lg}: any) {
    console.log(data)
    return (
        <div className={'box-elements bm4 top30'}>
            {data && data.length > 0 && (
                data.map((r: any, index: any) => {
                    return (
                        <Link to={`/${lg}/route/${r.id}`} key={index} className={'box-element'}>
                            <div className="img">
                                <img src={r.img}/>
                            </div>
                            <div className={'title'}>
                                {r.title}
                            </div>
                        </Link>
                    )
                }))}
        </div>
    )
}

export default BoxList;
