import {createControlComponent} from "@react-leaflet/core";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import {useEffect} from "react";
import L from "leaflet";
import "leaflet-routing-machine";


const at = 'pk.eyJ1IjoibWFya284NCIsImEiOiJjbG1oZHB0aWQwMG13M3Btdzd5bDlteHBoIn0.rjqdpVRw8ZDYX5tzIz_qMg'


function generateWayPoint(objects: any) {

    const wayPoints: any = [];
    objects.map((o: any) => {
        wayPoints.push(L.latLng(o.lat, o.lon))
    })
    return wayPoints
}

function createRoutineMachineLayer(prop: any) {

    function openMapObjectPreview(object: any) {
        if (prop.wayPointClickEvent) {
            prop.wayPointClickEvent(object);
        }
    }

    const waypoints = generateWayPoint(prop.objects)

    let routing: any = L.Routing.control({
        router: L.Routing.mapbox(at, {profile: 'mapbox/walking'}),
        waypoints: waypoints,
        lineOptions: {
            styles: [{color: "#8d096b", weight: 8, opacity: 0.5}],
            extendToWaypoints: false,
            missingRouteTolerance: 0
        },
        collapsible: true,
        show: true,
        fitSelectedRoutes: true,
        showAlternatives: true,
        addWaypoints: false,
        plan: L.Routing.plan(waypoints, {
            createMarker: function (i, wp) {
                const propIcon = prop.objects[i].icon;
                const mapIcon = propIcon ? `<div class="${propIcon}"></div>` : (i + 1).toString()

                const marker = L.marker(wp.latLng, {
                    draggable: false,
                    icon: L.divIcon({
                        className: `custom-waypoint-icon`,
                        html: `<div class="map-number">${(i + 1).toString()}</div><div class="map-icon">${mapIcon}</div>`,
                        iconSize: [36, 36]
                    }),

                });
                marker.on('click', () => {
                    prop.clearSelectedPoint()
                    const object = prop.objects[i]
                    openMapObjectPreview(object)
                    const currentOptions: any = marker.getIcon().options;
                    currentOptions.html = `<div class="map-number">${(i + 1).toString()}</div><div class="map-icon dark">${mapIcon}</div>`
                    currentOptions.className = 'custom-waypoint-icon selected ';
                    marker.setIcon(L.divIcon(currentOptions))
                })
                return marker
            },
            routeWhileDragging: false
        })
    });
    setTimeout(() => {prop.setRoutingValue(routing)}, 0)
    return routing;
}

const RouteComponent = createControlComponent(createRoutineMachineLayer);

export default RouteComponent;
